import { render, staticRenderFns } from "./nocontent.vue?vue&type=template&id=66f5c946&scoped=true&"
import script from "./nocontent.vue?vue&type=script&lang=js&"
export * from "./nocontent.vue?vue&type=script&lang=js&"
import style0 from "./nocontent.vue?vue&type=style&index=0&id=66f5c946&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f5c946",
  null
  
)

export default component.exports