<template>
  <div>
    <div class="content">
      <!-- <div class="content-font">{{ name }}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "暂无内容",
    },
  },
  created() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
};
</script>
<style lang='scss' scoped>
.content {
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 50%; /*偏移*/
  margin-top: 108px;
  display: flex;
  width: 216px;
  height: 206px;
  background-image: url("../../../assets/emptyState.png");
  background-size: 100%;
  // background-image:url('../../../assets/ShopImg/no-content.png');
  background-repeat: no-repeat;

  .content-font {
    width: 100%;
    position: absolute;
    bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #6b666b;
    text-align: center;
  }
}
</style>