<template>
  <div class="bigbox">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="商品列表"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div @touchmove="show = false" @mousewheel="show = false">
      <!-- <div style="height: 25px"></div> -->
      <!-- 搜索 -->
      <div class="top" :style="{ marginTop: isWxApplets ? '46px' : '' }">
        <!-- <img
          @click="$router.back(-1)"
          src="../../../assets/ShopImg/left.png"
          alt=""
        /> -->
        <van-search
          class="seach_e"
          v-model="value"
          shape="round"
          background="#fff"
          placeholder="请输入搜索关键词"
          @search="Search2"
        />
      </div>

      <div class="outside">
        <!-- 选择 -->
        <div class="select">
          <div @click="sortClick('Sort')">
            <p :class="sortField == 'Sort' ? 'selected' : ''">综合排序</p>
            <!-- <img class="three" src="../../../assets/ShopImg/three.png" alt="" /> -->
          </div>
          <div @click="sortClick('BuyCount')">
            <p :class="sortField == 'BuyCount' ? 'selected' : ''">销量</p>
            <img
              class="three"
              :src="
                sortType == 'desc'
                  ? require('../../../assets/ShopImg/three.png')
                  : require('../../../assets/ShopImg/up.png')
              "
              alt=""
              v-if="sortField == 'BuyCount'"
            />
          </div>
          <div @click="sortClick('DefaultPrice')">
            <p :class="sortField == 'DefaultPrice' ? 'selected' : ''">价格</p>
            <img
              class="three"
              :src="
                sortType == 'desc'
                  ? require('../../../assets/ShopImg/three.png')
                  : require('../../../assets/ShopImg/up.png')
              "
              alt=""
              v-if="sortField == 'DefaultPrice'"
            />
            <!-- <img
              class="three"
              src="../../../assets/ShopImg/up.png"
              alt=""
              v-if="sortField == 'DefaultPrice' && sortType == 'asc'"
            /> -->
          </div>
          <div @click="screen(0)">
            <!-- screen -->
            <p>筛选</p>
            <img class="sx" src="../../../assets/ShopImg/sx.png" alt="" />
          </div>
        </div>

        <div class="select selectFirst">
          <div>
            <p @click="screen(1)" :class="screenNum == 1 ? 'act' : ''">
              适用场景
            </p>
          </div>
          <div>
            <p @click="screen(2)" :class="screenNum == 2 ? 'act' : ''">
              艺术风格
            </p>
          </div>
          <div>
            <p @click="screen(3)" :class="screenNum == 3 ? 'act' : ''">
              艺术种类
            </p>
          </div>
        </div>
        <div class="select2"></div>
        <!-- 筛选框 -->

        <van-popup
          v-model="shows"
          round
          closeable
          position="bottom"
          :style="{ height: '80%' }"
        >
          <div class="pops">
            <div class="pops_title">
              {{
                screenNum == 0
                  ? "全部筛选"
                  : screenNum == 1
                  ? "适用场景"
                  : screenNum == 2
                  ? "艺术风格"
                  : screenNum == 3
                  ? "艺术种类"
                  : ""
              }}
            </div>
            <div v-if="screenNum === 0">
              <van-collapse
                v-model="activeNames"
                :border="false"
                style="marginbottom: 55px"
              >
                <van-collapse-item
                  title="价格范围"
                  :border="false"
                  size="large"
                  name="1"
                >
                  <div class="SelMoney">
                    <input
                      type="number"
                      max="7"
                      v-model="minPrice"
                      placeholder="最低价"
                    />
                    <div>——</div>
                    <input
                      type="number"
                      max="7"
                      v-model="maxPrice"
                      placeholder="最高价"
                    />
                  </div>
                </van-collapse-item>
                <!-- <div v-for="(item,index) in one" :key="index">
                        <div class="one">
                            {{item.text}}
                        </div>
                        <div class="two">
                            <div class="two2" v-for="(itemtwo,indextwo) in item.children" :key="indextwo">
                                <div class="two2-item" v-for="(itemthree,indexthree) in itemtwo.children" :key="indexthree">
                                  {{itemtwo.text}}/{{itemthree.text}}
                                </div>
                            </div>
                        </div>
                    </div> -->
                <div v-for="(item, index) in one" :key="index">
                  <van-collapse-item
                    v-if="item.children != null"
                    :title="item.text"
                    :border="false"
                    size="large"
                    :name="index"
                  >
                    <div
                      class="sel_type"
                      v-for="(itemtwo, indextwo) in item.children"
                      :key="indextwo"
                    >
                      <div
                        :class="[booleanId(itemtwo.id) ? 'act Sel' : 'Sel']"
                        @click="Search(itemtwo.id)"
                      >
                        {{ itemtwo.text }}
                      </div>
                      <div
                        :class="[booleanId(itemthree.id) ? 'act Sel' : 'Sel']"
                        v-for="(itemthree, indexthree) in itemtwo.children"
                        :key="indexthree"
                        @click="Search(itemthree.id)"
                      >
                        {{ itemtwo.text }}/{{ itemthree.text }}
                      </div>
                    </div>
                  </van-collapse-item>
                </div>

                <!-- <van-collapse-item
                  title="艺术风格"
                  :border="false"
                  size="large"
                  name="4"
                >
                  <div class="sel_type">
                    <div class="Sel" v-for="(item, i) in 4" :key="i">中式</div>
                  </div>
                </van-collapse-item> -->

                <!-- <van-collapse-item
                  title="艺术风格"
                  :border="false"
                  size="large"
                  name="5"
                >
                  <div class="sel_type">
                    <div class="Sel" v-for="(item, i) in 4" :key="i">中式</div>
                  </div>
                </van-collapse-item> -->

                <!-- <van-collapse-item
                  title="艺术风格"
                  :border="false"
                  size="large"
                  name="6"
                >
                  <div class="sel_type">
                    <div class="Sel" v-for="(item, i) in 4" :key="i">中式</div>
                  </div>
                </van-collapse-item> -->
              </van-collapse>

              <div class="btns">
                <div @click="Reset">重置</div>
                <div @click="Search2">确定</div>
              </div>
            </div>
            <div v-if="screenNum === 1">
              <van-collapse
                v-model="activeNamesScene"
                :border="false"
                style="marginbottom: 55px"
              >
                <div v-for="(item, index) in sceneList" :key="index">
                  <van-collapse-item
                    v-if="item.children != null"
                    :title="item.text"
                    :border="false"
                    size="large"
                    name="1"
                  >
                    <div
                      class="sel_type"
                      v-for="(itemtwo, indextwo) in item.children"
                      :key="indextwo"
                    >
                      <div
                        :class="[
                          booleanIdFirst(itemtwo.id) ? 'act Sel' : 'Sel',
                        ]"
                        @click="SearchFirst(itemtwo.id)"
                      >
                        {{ itemtwo.text }}
                      </div>
                      <div
                        :class="[
                          booleanIdFirst(itemthree.id) ? 'act Sel' : 'Sel',
                        ]"
                        v-for="(itemthree, indexthree) in itemtwo.children"
                        :key="indexthree"
                        @click="SearchFirst(itemthree.id)"
                      >
                        {{ itemtwo.text }}/{{ itemthree.text }}
                      </div>
                    </div>
                  </van-collapse-item>
                </div>
                <div class="btns">
                  <div @click="Reset">重置</div>
                  <div @click="Search2">确定</div>
                </div>
              </van-collapse>
            </div>
            <div v-if="screenNum === 2">
              <van-collapse
                v-model="activeNamesStyle"
                :border="false"
                style="marginbottom: 55px"
              >
                <div v-for="(item, index) in styleList" :key="index">
                  <van-collapse-item
                    v-if="item.children != null"
                    :title="item.text"
                    :border="false"
                    size="large"
                    name="1"
                  >
                    <div
                      class="sel_type"
                      v-for="(itemtwo, indextwo) in item.children"
                      :key="indextwo"
                    >
                      <div
                        :class="[booleanIdTwo(itemtwo.id) ? 'act Sel' : 'Sel']"
                        @click="SearchTwo(itemtwo.id)"
                      >
                        {{ itemtwo.text }}
                      </div>
                      <div
                        :class="[
                          booleanIdTwo(itemthree.id) ? 'act Sel' : 'Sel',
                        ]"
                        v-for="(itemthree, indexthree) in itemtwo.children"
                        :key="indexthree"
                        @click="SearchTwo(itemthree.id)"
                      >
                        {{ itemtwo.text }}/{{ itemthree.text }}
                      </div>
                    </div>
                  </van-collapse-item>
                </div>

                <div class="btns">
                  <div @click="Reset">重置</div>
                  <div @click="Search2">确定</div>
                </div>
              </van-collapse>
            </div>
            <div v-if="screenNum === 3">
              <van-collapse
                v-model="activeNamesCategory"
                :border="false"
                style="marginbottom: 55px"
              >
                <div v-for="(item, index) in categoryList" :key="index">
                  <van-collapse-item
                    v-if="item.children != null"
                    :title="item.text"
                    :border="false"
                    size="large"
                    name="1"
                  >
                    <div
                      class="sel_type"
                      v-for="(itemtwo, indextwo) in item.children"
                      :key="indextwo"
                    >
                      <div
                        :class="[
                          booleanIdThree(itemtwo.id) ? 'act Sel' : 'Sel',
                        ]"
                        @click="SearchThree(itemtwo.id)"
                      >
                        {{ itemtwo.text }}
                      </div>
                      <div
                        :class="[
                          booleanIdThree(itemthree.id) ? 'act Sel' : 'Sel',
                        ]"
                        v-for="(itemthree, indexthree) in itemtwo.children"
                        :key="indexthree"
                        @click="SearchThree(itemthree.id)"
                      >
                        {{ itemtwo.text }}/{{ itemthree.text }}
                      </div>
                    </div>
                  </van-collapse-item>
                </div>

                <div class="btns">
                  <div @click="Reset">重置</div>
                  <div @click="Search2">确定</div>
                </div>
              </van-collapse>
            </div>
          </div>
        </van-popup>

        <!-- 下拉框 -->

        <div
          class="pop"
          v-show="show"
          @click="cancel"
          @touchmove.prevent
          @mousewheel.prevent
        >
          <div
            class="pop_div"
            @click.stop
            @touchmove.prevent
            @mousewheel.prevent
            @touchmove.stop
            @mousewheel.stop
          >
            <div><span>综合</span></div>
            <div><span>信用</span></div>
          </div>
        </div>
        <!-- 内容 -->

        <div class="content">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQeryShopGoodsList"
          >
            <Waterfall
              ref="waterfall"
              :list="goodsList"
              :gutter="13"
              backgroundColor="#ffffff"
              :breakpoints="{ 500: { rowPerView: 2 } }"
            >
              <template slot="item" slot-scope="props">
                <div style="overflow: hidden">
                  <div :style="initCardStyle(props)">
                    <router-link
                      :to="
                        '/shop/goods/detail/?UserId=' +
                        user.Id +
                        '&id=' +
                        props.data.Id
                      "
                      class="for_you"
                    >
                      <img
                        :src="props.data.CoverImg"
                        alt=""
                        @load="$refs.waterfall.refresh()"
                      />
                      <div class="hot_conntent_div_sub">
                        {{ props.data.GoodsName }}
                      </div>
                      <div class="hot_conntent_div_money for_you_money">
                        <span>￥</span
                        ><span>{{ props.data.DefaultPrice }}</span>
                        <span>{{ props.data.BuyCount }}人付款</span>
                      </div>

                      <div class="for_you_money yh">
                        <span>券</span>
                        <span
                          >满{{ props.data.FullMoeny }}减{{
                            props.data.CouponMoeny
                          }}</span
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
              </template>
            </Waterfall>
          </van-list>
        </div>
        <div v-if="goodsList.length <= 0" style="background-color: #fff">
          <nocontent name="暂无数据"></nocontent>
          <!-- <img style="width: 100%" src="@/assets/emptyState.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  qeryShopGoodsList,
  queryGoodsCategoryTree,
  queryGoodsPropertyTree,
} from "@/api/shop";
import Waterfall from "vue-waterfall-plugin";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    Waterfall,
    nocontent,
  },
  data() {
    return {
      show: false, //综合
      shows: false, //筛选
      activeNames: ["1", "2", "3", "4"],
      value: "",
      cur: "",
      goodsList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      categoryIds: "",
      isNew: "",
      isPreferential: "",
      minPrice: "", //最低价
      maxPrice: "", //最高价
      minPoints: "",
      maxPoints: "",
      sortType: "asc",
      sortField: "Sort",
      twoarr: [],
      one: [],
      showPopoverScene: false,
      showPopoverStyle: false,
      showPopoverCategory: false,
      activeNamesPop: [],
      sceneList: [],
      styleList: [],
      categoryList: [],
      screenNum: null,
      activeNamesScene: ["1"],
      activeNamesStyle: ["1"],
      activeNamesCategory: ["1"],
      scenePropertyIds: [],
      stylePropertyIds: [],
      categoryPropertyIds: [],
      isSetInitStyle: true,
      isWxApplets: true,
      user:{}
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "商品列表";
      this.isWxApplets = false;
    }
    if (
      this.$route.query.UserId != undefined &&
      this.$route.query.UserId != ""
    ) {
      this.user.Id = this.$route.query.UserId;
    } else {
      if (localStorage.getItem("userInfo") != null) {
        this.user = JSON.parse(localStorage.getItem("userInfo"));
      } else {
        this.user.Id = 0;
      }
    }
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
    this.categoryIds = [this.$route.query.categoryId];
    this.isNew = this.$route.query.isNew;
    this.isPreferential = this.$route.query.isPreferential;
    this.value = this.$route.query.key;
    this.categoryIds = this.categoryIds.filter((item) => item);
    // this.postQeryShopGoodsList();
    this.postQeryShopGoodsCategoryList();
    this.getQueryGoodsPropertyTree(0);
    this.getQueryGoodsPropertyTree(1);
    this.getQueryGoodsPropertyTree(2);
    // window.addEventListener('scroll', this.listenScroll);
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    // 初始化卡片样式
    initCardStyle(props) {
      if (this.isSetInitStyle) {
        return {
          width: `${props.data.itemWidth}px`,
          height: `${
            ((props.data.itemWidth - 20) / props.data.width) * props.data.height
          }px`,
          // backgroundColor: props.data.blankColor
        };
      }
    },
    async getQueryGoodsPropertyTree(num) {
      let parm = "?category=" + num;
      const res = await queryGoodsPropertyTree(parm);
      if (num == 0) {
        this.sceneList = [
          {
            children: res.response,
            text: "适用场景",
          },
        ];
      } else if (num == 1) {
        this.styleList = [
          {
            children: res.response,
            text: "艺术风格",
          },
        ];
      } else if (num == 2) {
        this.categoryList = [
          {
            children: res.response,
            text: "艺术种类",
          },
        ];
      }
    },
    screen(num) {
      this.screenNum = num;
      this.shows = true;
    },
    booleanId(id) {
      return this.categoryIds.some((item) => {
        return item === id;
      });
    },
    booleanIdFirst(id) {
      return this.scenePropertyIds.some((item) => {
        return item === id;
      });
    },
    booleanIdTwo(id) {
      return this.stylePropertyIds.some((item) => {
        return item === id;
      });
    },
    booleanIdThree(id) {
      return this.categoryPropertyIds.some((item) => {
        return item === id;
      });
    },
    recommend() {
      this.show = !this.show;
    },
    cancel() {
      this.show = false;
      console.log(1);
    },
    Reset() {
      this.minPrice = "";
      this.maxPrice = "";
      this.shows = false;
      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      if (this.screenNum == 0) {
        this.categoryIds = [];
      } else if (this.screenNum == 1) {
        this.scenePropertyIds = [];
      } else if (this.screenNum == 2) {
        this.stylePropertyIds = [];
      } else if (this.screenNum == 3) {
        this.categoryPropertyIds = [];
      }
      this.postQeryShopGoodsList();
    },
    SearchFirst(id) {
      if (this.scenePropertyIds.includes(id)) {
        this.scenePropertyIds = this.scenePropertyIds.filter((item) => {
          return item !== id;
        });
      } else {
        this.scenePropertyIds.push(id);
      }
    },
    SearchTwo(id) {
      if (this.stylePropertyIds.includes(id)) {
        this.stylePropertyIds = this.stylePropertyIds.filter((item) => {
          return item !== id;
        });
      } else {
        this.stylePropertyIds.push(id);
      }
    },
    SearchThree(id) {
      if (this.categoryPropertyIds.includes(id)) {
        this.categoryPropertyIds = this.categoryPropertyIds.filter((item) => {
          return item !== id;
        });
      } else {
        this.categoryPropertyIds.push(id);
      }
    },
    Search(id) {
      // this.shows = false;
      // this.pageIndex = 1;
      // this.goodsList = [];
      // this.isEnd = false;
      if (this.categoryIds.includes(id)) {
        this.categoryIds = this.categoryIds.filter((item) => {
          return item !== id;
        });
      } else {
        this.categoryIds.push(id);
      }
      // this.categoryIds = [id]; //筛选id
      // this.postQeryShopGoodsList();
      // console.log(this.categoryIds, '')
    },
    Search2() {
      this.shows = false;
      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    SelOne(i) {
      this.cur = i;
    },
    sortClick(sortField) {
      if (this.sortField != sortField) {
        this.sortType = "asc";
      } else {
        if (this.sortType == "asc") {
          this.sortType = "desc";
        } else if (this.sortType == "desc") {
          this.sortType = "asc";
        }
      }
      this.sortField = sortField;
      if (this.sortField == "Sort") {
        this.sortType = "asc";
      }

      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    // 列表数据
    async postQeryShopGoodsList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      // console.log(this.categoryIds, '')
      parm.categoryIds = this.categoryIds;
      parm.isNew = this.isNew;
      parm.isPreferential = this.isPreferential;
      if (this.stylePropertyIds.length > 0)
        parm.stylePropertyIds = this.stylePropertyIds;
      if (this.scenePropertyIds.length > 0)
        parm.scenePropertyIds = this.scenePropertyIds;
      if (this.categoryPropertyIds.length > 0)
        parm.categoryPropertyIds = this.categoryPropertyIds;
      parm.IsShelf = 1;
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      const res = await qeryShopGoodsList(parm);
      this.goodsList = this.goodsList.concat(res.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.goodsList.length >= res.dataCount) {
        this.finished = true;
      }
      this.$nextTick(() => {
        this.$refs.waterfall.refresh();
      });
      // this.isLoad = false;
      // // console.log(res, 88798);
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    // 请求筛选类型
    async postQeryShopGoodsCategoryList() {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      // this.categoryList = res.response;
      this.navItem = res.response;
      // this.curCategory =this.navItem.children != null? this.navItem.children[0]:{};
      // this.curCategory =this.navItem.children != null? this.navItem.children[0]:{};
      this.one = this.navItem;
      // console.log(this.one, 789798);
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.postQeryShopGoodsList();
        }
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  align-items: center;
  padding: 0 3% 0 4%;
  img {
    width: 18px;
    height: 18px;
  }

  .seach_e {
    width: 100%;

    .van-search__content {
      border: 1px solid #000000;
    }
  }
}

.outside {
  width: 90%;
  margin: 0 auto;
  .selectFirst {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    display: flex;
    z-index: 200;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    div {
      // width: 33.33%;
      // text-align: center;
    }
  }
  .selectFirst {
    margin: 10px 0;
    p {
      width: 5.5625rem;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #f6f6f6;
      border-radius: 30px;
    }
  }
  .select {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    display: flex;
    z-index: 200;
    background: #fff;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      align-items: center;

      p {
        color: #a7a7a8;
      }

      .three {
        width: 8px;
        height: 6px;
        margin-left: 5px;
      }

      .sx {
        width: 16px;
        height: 20px;
        margin-left: 3px;
        margin-top: 3px;
      }
    }

    .selected {
      color: #000000;
    }
  }

  .hot_conntent_div_money span:nth-child(1) {
    color: #ff4511;
    font-size: 10px;
  }

  .hot_conntent_div_money span:nth-child(2) {
    color: #ff4511;
    font-weight: bold;
    font-size: 15px;
  }

  .hot_conntent_div_money span:nth-child(3) {
    color: #c9c9c9;
    font-size: 12px;
    margin-left: 5px;
    font-weight: 100;
  }

  .hot_conntent_div_sub {
    width: 120px;
    // height: 44px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    font-weight: bold;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .hot_conntent_div_sub2 {
    width: 120px;

    font-size: 12px;
    color: #999;
  }

  .content {
    margin-top: 10px;
    z-index: -100;
    background-color: #f5f5f5;
    margin: 0 -5%;
  }

  .for_you {
    // width: 50%;
    width: 200px;
    margin-bottom: 15px;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      border-radius: 6px;
    }

    .for_you_money {
      height: 30px;
      line-height: 30px;
      float: left;
      font-weight: 400;
    }

    .yh span {
      font-size: 8px;
      color: #ff4511;
      padding: 2px 5px;
      border: 1px solid #ff4511;
      border-radius: 4px;
      margin-right: 5px;
    }
  }
}

.pop {
  width: 100%;
  left: 0;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;

  .pop_div {
    width: 100%;
    height: 200px;
    background: #fff;
    border-radius: 0 0 20px 20px;

    div {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #f5f5f5;

      span {
        margin-left: 17px;
      }
    }
  }
}

.pops {
  // padding: 0 15px;
  padding-bottom: 30px;
  .one {
    font-size: 16px;
    color: #323233;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px 16px;
  }

  .pops_title {
    color: #333333;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-top: 14px;
  }
}

/deep/ .van-cell__title {
  font-weight: bold;
}

.SelMoney {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 40%;
    height: 34px;
    background: #f6f6f6;
    border-radius: 30px;
    font-size: 13px;
    border: none;
    line-height: 34px;
    text-align: center;
  }
}

.sel_type {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;

  .Sel {
    min-width: 25%;
    margin-right: 15px;
    padding: 0 10px;
    display: inline-block;
    margin-bottom: 10px;
    height: 32px;
    background: #f6f6f6;
    color: #090909;
    border-radius: 30px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #f6f6f6;
  }
}

.act {
  border: 1px solid #000000 !important;
  border-radius: 30px;
  background: #ffffff !important;
  color: #000000 !important;
}
// .Sel:last-child:nth-child(3n - 1) {
//   margin-right: calc(32% + 6% / 2);
// }

.btns {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
}
.btns div:first-child {
  width: 40%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #000000;
  color: #040404;
}
.btns div:last-child {
  width: 40%;
  height: 40px;
  line-height: 42px;
  text-align: center;
  border-radius: 30px;
  background: linear-gradient(99deg, #000000 0%, #000000 100%);
  color: #fff;
}
.two {
  color: #000000;
}
/deep/.sel_type .Sel {
  overflow: hidden;
}
/deep/.waterfull-grid {
  background: none !important;
  .waterfull-item-box {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    .for_you_money {
      float: none;
      padding: 0 10px;
    }
    .hot_conntent_div_sub {
      padding: 0 10px;
      margin: 0;
      margin-top: 10px;
      width: 100%;
    }
    .for_you_money {
      padding: 0 10px;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
